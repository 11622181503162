import { Router }       from "@angular/router";
import { Component }    from "@angular/core";
import { environment }  from "src/environments/environment";
import { Resume }       from "src/app/zothers/models";
import { UtilsService } from "src/app/services/utils.service";
import { AlertService } from "src/app/services/alert.service";


@Component({ selector: "resume", templateUrl: "./user-resume.component.html", styleUrls: ["./user-resume.component.scss"]}) export class UserResumeComponent {

    public user:string = "";
    public resume:Resume = null;
    public resumeNotFound:boolean = false;

    constructor(private router:Router, public utils:UtilsService, private alertServ:AlertService) {
        this.user = this.router.url.substr(1);
        fetch(environment.firebase.urlCdnPerfis + this.user + "-resume.json?alt=media").then(r => {
            r.text().then(d => {
                if (JSON.parse(d).error) this.resumeNotFound = true; 
                else {
                    this.resume = JSON.parse(d);
                    console.warn(this.resume.education);
                    this.utils.titleText(this.resume.wantToBeCalled);
                    setInterval(() => { // gambiarra para colocar o tamanho certo na section left 
                        var width = window.getComputedStyle(document.getElementsByTagName("html")[0]).getPropertyValue("width").replace("px", "");
                        if (Number.parseInt(width) > 920) {
                            var hRight:string = window.getComputedStyle(document.getElementsByClassName("section_right")[0]).getPropertyValue("height");
                            var hLeft:string = window.getComputedStyle(document.getElementsByClassName("section_left")[0]).getPropertyValue("height");
                            if (hRight != hLeft) document.getElementsByClassName("section_left")[0].setAttribute("style", "height: " + (Number.parseInt(hRight.replace("px", "")) >= Number.parseInt(hLeft.replace("px", "")) ? hRight : "auto"));
                        }
                        else {
                            document.getElementsByClassName("section_left")[0].setAttribute("style", "height: auto");
                        }
                    }, 1000);
                }
            })
        });
    }

    public dateDiff(date1:string, date2:string):string {
        var diff:number[] = this.utils.dateDiff(date1, date2);
        var yText:string = 
            diff[0] == 1 ? "ONE YEAR"         : 
            diff[0] >  1 ? diff[0] + " YEARS" :
            ""
        ;
        var mText:string = 
            diff[1] == 1 ? ", ONE MONTH"              : 
            diff[1]  > 1 ? ", " + diff[1] + " MONTHS" :
            ""
        ;
        return yText + mText;
    }

    public copyLink():void {
        this.utils.copy(window.location.href);
        this.alertServ.snackbar({ msg: "LINK COPIED", type:"normal", timeOut: 800, closeBtn: false });
    }

    public share(type:string):void {
        var url:string = 
            type == "twitter"  ? "https://twitter.com/intent/tweet?text=" + encodeURIComponent("Check out " + this.resume.wantToBeCalled + "'s Resume at " + window.location.href) :
            type == "facebook" ? "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href :
            type == "linkedin" ? " https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href : 
            ""
        ;
        this.utils.openLinkInNewTab(url);
    }

}

