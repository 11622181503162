
export const environment = {
    production: false, 
    loginRedirectUrl: "http://localhost:4200/app/login",
    domain: "localhost:4200/", 

    googleCloud: {
        clientId: "868283324550-fil3f88o7kdmb4a8tqh8cl926b9rt5qg.apps.googleusercontent.com", 
        clietSecret: "f0Ott3Yla_uTgFKZ7bxteqet", 
        tokenUrl: "https://oauth2.googleapis.com/token"
    },

    firebase: {
        credentials: {
            apiKey: "AIzaSyDbboOyABy370HDCzK0bCHz0M4eSAqN7D0",
            authDomain: "site-de-curriculos.firebaseapp.com",
            projectId: "site-de-curriculos",
            storageBucket: "site-de-curriculos.appspot.com",
            messagingSenderId: "868283324550",
            appId: "1:868283324550:web:6f8798f674323e5db55907"
        }, 
        collections: {
            users: "dev-users", 
            resumesKeys: "dev-resumes-keys"
        }, 
        storageFolders: {
            perfis: "dev-perfis"
        }, 
        urlCdnPerfis: "https://firebasestorage.googleapis.com/v0/b/site-de-curriculos.appspot.com/o/perfis%2F"
    }, 

    facebook: {
        credentials: {
            appId      : "828233664528819",
            cookie     : true,
            xfbml      : true,
            version    : "v3.0"
        }, 
        apiUrl: "https://graph.facebook.com/"
    }

};
