
export class Resume {
    public resumeKey:string;
    public name:string;
    public wantToBeCalled:string;
    public fromCity:string;
    public currentCity:string; 
    public children:number;
    public dateBirth:string; 
    public email:string;
    public cellphone:string; 
    public site:string;
    public smoker:boolean; 
    public single:boolean;
    public photoBase64:string;
    public showShareButtons:boolean;
    public about:string[];
    public experiences:Experience[]; 
    public skills:Skill[];
    public languages:Language[];
    public education:Diploma[];
    public otherSections:ResumeSection[];
    public constructor() {
        var ret = {
            photoBase64: "../../../assets/person.png", 
            resumeKey: null, name: null, wantToBeCalled: null, fromCity: null, currentCity: null, children: null, dateBirth: null, email: null, cellphone: null, 
            site: null, smoker: null, single: null, showShareButtons: true, about: [], experiences: [new Experience()], skills: [new Skill()], 
            languages: [new Language()], education: [new Diploma()], otherSections: []
        };
        return ret;
    }
}

export class Experience {
    public title:string; 
    public company:string;
    public dateBegin:string;
    public dateEnd:string;
    public description:string;
    public constructor() {
        return { title: null, company: null, description: null, dateBegin: null, dateEnd: null };
    }
}

export class Skill {
    public title:string;
    public description:string;
    public years:number;
    public percent:number;
    public constructor() {
        return { title: null, description: null, years: null, percent: 50 };
    }
}

export class Language {
    public title:string;
    public percent:number;
    public constructor() {
        return { title: null, percent: 50 };
    }
}

export class Diploma {
    public title:string;
    public institution:string;
    public dateBegin:string;
    public dateEnd:string;
    public description:string;
    public constructor() {
        return { title: null, institution: null, dateBegin: null, dateEnd: null, description: null };
    }
}

export class ResumeSection {
    public title:string;
    public items:ItemOfResumeSection[];
    public constructor(title:string) {
        return { title: title, items: [ new ItemOfResumeSection() ] };
    }
}

export class ItemOfResumeSection {
    public title:string;
    public subtitle:string;
    public description:string;
    public constructor() {
        return { title: null, subtitle: null, description: null };
    }
}

export class User {
    public id:string;
    public email:string;
    public name:string;
    public sso:string;
    public cvCount:number;
    public since:string;
    public resumesKeys:string[];
}
