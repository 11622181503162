import { Injectable } from "@angular/core";


@Injectable() export class LangService {

    public d:any = this.newDictionary();
    public currentLang:("pt" | "en");

    constructor() { 
    }

    public sendNewDictionary(l:("pt" | "en") = null):void {
        this.d = this.newDictionary(l);
    }

    public bind(key:string, ...params):string {
        var ret:string = this.d[key];
        for (var i = 0; i < params.length; i++) ret = ret.replace("{" + i + "}", params[i]);
        return ret;
    }

    private newDictionary(l:("pt" | "en") = "en"):object {
        this.currentLang = l;
        return {} 
    }

}
