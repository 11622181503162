import { NgModule }             from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent }       from "./pages/admin/admin.component";
import { LoginComponent }       from "./pages/login/login.component";
import { UserResumeComponent }  from "./pages/user-resume/user-resume.component";
import { WelcomeComponent }     from "./pages/welcome/welcome.component";


const routes: Routes = [
    { path: ""         , component: WelcomeComponent    },
    { path: "app"      , component: WelcomeComponent    }, 
    { path: "app/login", component: LoginComponent      }, 
    { path: "app/admin", component: AdminComponent      }, 
    { path: "**"       , component: UserResumeComponent }
];

@NgModule({imports: [RouterModule.forRoot(routes)], exports: [RouterModule]}) export class AppRoutingModule { }






