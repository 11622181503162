import { Component }    from "@angular/core";
import { Router }       from "@angular/router";
import { UtilsService } from "src/app/services/utils.service";
import { environment } from "src/environments/environment";


@Component({selector: "app-welcome", templateUrl: "./welcome.component.html", styleUrls: ["./welcome.component.scss"]}) export class WelcomeComponent {

    public snack:any = null;

    constructor(private router:Router, private utilsServ:UtilsService) {}

    public goToLoginPage():void {
        this.router.navigate(["/app/login"]);
    }

    public openDemo():void {
        this.utilsServ.openLinkInNewTab("http" + (environment.production ? "s" : "") + "://" + environment.domain + "demo");
    }
    
}
