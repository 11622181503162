import { Injectable }   from "@angular/core";
import { User }         from "../zothers/models";
import * as CryptoJS    from 'crypto-js';


@Injectable() export class UtilsService {

    public userLogado:boolean = false;
    public user:User = null;
    public primeiroAcesso = false;
    private aesPW:string = "7418965"; // P

    constructor() {
    }

    public jwtDecode(token):any {
        var base64Url:string = token.split(".")[1];
        var base64:string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(atob(base64).split("").map( (c) => { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); } ).join(""));
        return JSON.parse(jsonPayload);
    };

    public saveEncryptedUserOnSessionStorage(user:User):void {
        this.user = user;
        this.userLogado = true;
        var encrypted:string = CryptoJS.AES.encrypt(user.id.trim(), this.aesPW);
        window.sessionStorage.setItem("id", encrypted);
    }

    public getEncryptedUserFromSessionStorage():string {
        var encrypted:string = window.sessionStorage.getItem("id");
        if (encrypted) return CryptoJS.AES.decrypt(encrypted, this.aesPW).toString(CryptoJS.enc.Utf8); else return null;
    }

    public dateIsValid(d:Date):boolean {
        return (d instanceof Date) && !isNaN(d as any) && (d.getFullYear() < 2030) && (d.getFullYear() > 1999); 
    } 

    public dia(data:Date = new Date(), strComDoisDigitos:boolean = false):any {
        var dia:number = data.getDate();
        return strComDoisDigitos ? (dia < 10 ? ("0" + dia) : dia.toString()) : dia;
    }

    public mes(data:Date = new Date(), strComDoisDigitos:boolean = false):any {
        var dia:number = data.getMonth() + 1;
        return strComDoisDigitos ? (dia < 10 ? ("0" + dia) : dia.toString()) : dia;
    }

    public year(date:string):any { 
        return (new Date(date)).getFullYear();
    }

    public yyyymmdd(data:Date = new Date(), separador:string = "-"):string {
        return data.getFullYear() + separador + this.mes(data, true) + separador + this.dia(data, true);
    }

    public dateMothYearDescription(date:string):string {
        const d = new Date(date);
        const m = d.toLocaleString("default", { month: "short" });
        return m + " " + d.getFullYear();
    }

    /** retorna uma matriz com a diferença no formato [anos, meses, dias], quando não tem um elemento retorna nulo, exemplo: [null, meses, dias], ou [anos, null, null] */
    public dateDiff(date1:string, date2:string):number[] {
        var countDays:number = Math.floor(((new Date(date2)).getTime() - (new Date(date1)).getTime()) / (1000 * 60 * 60 * 24)); // milisecs * secs * mins * hours
        var years:number = Math.floor(countDays / 365);
        var resto:number = countDays - (years * 365);
        var months:number = Math.floor(resto / 30);
        var days:number = resto - (months * 30);
        return [ (years > 0 ? years : null), (months > 0 ? months : null), (days > 0 ? days : null) ];
    }

    public copy(str:string) {
        try { // OPÇÃO 1 geralmente só funciona em https com certificado válido
            navigator.clipboard.writeText(str);
        }
        catch { // OPÇÃO 2
            const element = document.createElement("TEXTAREA") as HTMLTextAreaElement;
            document.body.appendChild(element);
            element.value = str;
            element.select();
            document.execCommand("copy");
            document.body.removeChild(element);
        } // para ler o texto do clipboard navigator.clipboard.readText().then();
    }

    public openLinkInNewTab(url:string):void {
        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
    }

    public titleText(text:string):void {
        setTimeout(() => document.getElementById("titulo").textContent = text, 500);
    }

}
