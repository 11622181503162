import { Injectable }   from "@angular/core";
import { environment }  from "src/environments/environment";
import { Resume, User } from "src/app/zothers/models";
import { HttpClient }   from "@angular/common/http";
import * as firebase    from "firebase/app";
import { UtilsService } from "./utils.service";
import                       "firebase/storage";

/* PASSO A PASSO PARA O FIREBASE APP
    -- primeiro vamos configurar o app, ele te dará acesso a base nosql firestore e ao storage cdn
    -- dentro do projeto criar uma firestore database e criar uma coleção
    -- entrar nas regras e alterar o false para true, vai ficar assim: allow read, write: if true;
    -- se for usar storage cdn, tem que configurar as regras do storage também, ou vai retornar erro 403
    -- npm install firebase@7.14.0
    -- npm install @angular/fire@^5.4.0
    -- no app module colocar: import { AngularFirestoreModule } from "@angular/fire/firestore";
    -- se também for usar o storage cdn, incluir: import "firebase/storage"; é desse jeito mesmo, sem nada mais
    -- no app module colocar: imports > AngularFirestoreModule
    -- no console do firebase, página inicial dentro do projeto, abaixo do nome do projeto, tem o botão adicionar app, clicar e adicionar o app de web, sem hosting
    -- isso vai gerar o json firebaseConfig, copiar esse json para o enviroment ou outro lugar de acesso global
    -- neste arquivo de service colocar: import * as firebase  from "firebase/app";
    -- neste arquivo de service, dentro da class, colocar: private db = ... igual na classe abaixo
    -- fim
*/


@Injectable() export class HttpService {

    // antes de se iniciar o app é preciso verificar se já existe uma instância, se sim, pega-se a instância que já existe, do contrário cria-se outra
    private firebaseApp = firebase.apps.length == 0 ? firebase.initializeApp(environment.firebase.credentials) : firebase.app();
    
    constructor(private http:HttpClient, private utils:UtilsService) {        
    }




    // FIREBASE FIRESTORE NOSQL ===============================================================================================================================

    private usersDB = this.firebaseApp.firestore().collection(environment.firebase.collections.users);
    private resumesKeysDB = this.firebaseApp.firestore().collection(environment.firebase.collections.resumesKeys);


    public createUser(user:User):Promise<void> {
        return this.usersDB.doc(user.id).set(user) ;
    }

    public async readUser(id:string):Promise<User> {
        var user:User = null;
        await this.usersDB.doc(id).get().then(doc => user = <User>doc.data());
        return user;
    }

    public updateUser(user:User):Promise<void> {
        return this.usersDB.doc(user.id).update(user);
    }

    public deleteUser(id:string):Promise<any> {
        return this.usersDB.doc(id).delete();
    }

    public async readAllUsers():Promise<User[]> {
        var r = await this.usersDB.get();
        var mat:User[] = r.docs.map(doc => <User>doc.data()); 
        return mat;
    }

    public async readUsersFromBrazil():Promise<User[]> { 
        var r = await this.usersDB.where("country", "==", "Brazil").get();
        var mat:User[] = r.docs.map(doc => <User>doc.data()); 
        return mat;
    }

    public async checkResumeKey(key:string):Promise<boolean> {
        var ret:any = null;
        await this.resumesKeysDB.doc(key).get().then(doc => ret = doc.data());
        return !!ret;
    }

    public createResumeKey(key:string):Promise<void> {
        return this.resumesKeysDB.doc(key).set({}) ;
    }

    public deleteResumeKey(key:string):Promise<void> {
        return this.resumesKeysDB.doc(key).delete();
    }






    // FIREBASE STORAGE CDN ===================================================================================================================================

    private perfis = this.firebaseApp.storage().ref().child(environment.firebase.storageFolders.perfis);

    public deleteResume(key:string):Promise<any> {
        return this.perfis.child(key + "-resume.json").delete().then(() => {
            this.utils.user.resumesKeys.splice(this.utils.user.resumesKeys.indexOf(key), 1);
            this.updateUser(this.utils.user);
            this.deleteResumeKey(key);
        });
    }

    public uploadResume(resume:Resume, isNew:boolean):Promise<any> {
        return this.perfis.child(resume.resumeKey + "-resume.json").put(new Blob([JSON.stringify(resume)], { type: "application/json" })).then(() => {
            if (isNew) {
                this.utils.user.resumesKeys.push(resume.resumeKey);
                this.utils.user.cvCount++;
                this.updateUser(this.utils.user);
                this.createResumeKey(resume.resumeKey);
            }
        });
    }



    
    // APIS ===================================================================================================================================================

    public googleTrocarTokenPorDados(token:string):Promise<any> {
        var body = {
            code         : token, 
            client_id    : environment.googleCloud.clientId, 
            client_secret: environment.googleCloud.clietSecret, 
            redirect_uri : environment.loginRedirectUrl, 
            grant_type   : "authorization_code"
        };
        return this.http.post(environment.googleCloud.tokenUrl, body).toPromise();
    }

    public facebookTrocarTokenPorDados(id:string, token:string):Promise<any> {
        return this.http.get(environment.facebook.apiUrl + id + "?name,email&access_token=" + token).toPromise();
    }


}
