import { Injectable } from "@angular/core"; 


/* EXEMPLOS
    this.alertServ.snackbar({ msg: "sucesso", closeBtn: true, timeOut: 3000, type: "success" });
    this.alertServ.snackbar({ msg: "aviso", closeBtn: true, timeOut: 3500, type: "warning" });
    this.alertServ.snackbar({ msg: "erro", closeBtn: true, timeOut: 4000, type: "error" }); 
    this.alertServ.snackbar({ msg: "com botões!", closeBtn: true, icon: "notifications_active", btns: [
        { text: "1", onclick: (() => this.alertServ.snackbar({ msg: "você clicou no botão 1, ual!", type: "success", timeOut: 2000 })) }, 
        { text: "2", onclick: (() => this.alertServ.snackbar({ msg: "você clicou no botão 2, ual!", type: "success", timeOut: 2000 })) }, 
    ]});
    this.alertServ.snackbar({ msg: "este é clicável, clica!", closeBtn: true, icon: "touch_app", onClick: (() => this.alertServ.snackbar({ msg: "você clicou no snackbar, uhuul!", type: "success", timeOut: 2000 })) }); 
*/

@Injectable() export class AlertService { 

    // ALERTAS ======================================================================================================================================

    private css:string = "display: flex; justify-content: space-between; font-family: Roboto; align-items: center; margin-top: 4px; padding: 4px 20px; font-size: 16px; color: white; height: 50px; text-align: center; border-radius: 2px;";
    private conteiner:HTMLDivElement;

    constructor() {
        this.conteiner = document.createElement("div");
        this.conteiner.setAttribute("style", "display: flex; flex-direction: column; position: fixed; z-index: 1; bottom: 22px; right: 22px;")
        this.conteiner.setAttribute("id", "alertsConteiner");
        document.body.appendChild(this.conteiner);
    }

    public snackbar(config:{ msg?:string, timeOut?:number, style?:string, icon?:string, closeBtn?:boolean, type?:("error" | "normal" | "warning" | "processing"), onClick?:any, btns?:{ text:string, onclick:any }[] } = null):HTMLDivElement {
        if (!config) return null; 
        config.icon = 
            config.icon                 ? config.icon :
            config.type == "error"      ? "fa fa-bug" :
            config.type == "normal"     ? "fa fa-check" : 
            config.type == "warning"    ? "fa fa-exclamation" : 
            config.type == "processing" ? "fa fa-ellipsis-h" :
            null
        ;
        var bgColor:string = 
            config.type == "error"   ? "background-color: #16303c;" :
            config.type == "normal"  ? "background-color: #16303c;" : 
            config.type == "warning" ? "background-color: #16303c;" : 
            "background-color: #16303c;"
        ; 
        var btns:string = "";
        if (config.btns) {
            for (var i:number = 0; i < config.btns.length; i++) btns += "<span id='btn" + i + "' style='cursor: pointer; border: 1px solid rgba(255, 255, 255, 0.2); border-radius: 2px; padding: 4px 12px; margin-left: " + (i == 0 ? "20" : "2") + "px; font-size: 16px; background-color: rgba(0, 0, 0, 0.15); opacity: 0.8;'>" + config.btns[i].text + "</span>"; 
        }
        var e:HTMLDivElement = document.createElement("div");
        e.innerHTML = 
            (config.icon ? "<i style='opacity: 0.8; margin-right: 15px; font-size: 25px' class='" + config.icon + "'></i>" : "") + 
            (config.msg  ? "<span>" + config.msg + "</span>" : "") + btns + 
            (config.closeBtn === false ? "" : "<i style='cursor: pointer; margin-left: 20px; font-size: 25px; opacity: 0.7;' class='fa fa-times'></i>")
        ;
        e.setAttribute("style", this.css + bgColor + (config.style ? config.style : "") + (config.onClick ? "cursor: pointer;" : "")); 
        if (e.getElementsByTagName("i")[1]) e.getElementsByTagName("i")[1].addEventListener("mousedown", () => this.snackbarClose(e));
        if (config.btns) {
            for (var i:number = 0; i < config.btns.length; i++) {
                var btn:HTMLElement = e.querySelector("#btn" + i) as HTMLElement;
                btn.addEventListener("mousedown", config.btns[i].onclick);
                btn.addEventListener("mousedown", () => this.snackbarClose(e));
            } 
        }
        if (config.onClick) {
            var span:HTMLElement = e.getElementsByTagName("span")[0];
            span.addEventListener("mousedown", config.onClick);
            span.addEventListener("mousedown", () => this.snackbarClose(e));
        }
        this.conteiner.appendChild(e); 
        if (config.timeOut) setTimeout(() => { this.snackbarClose(e); }, config.timeOut); 
        return e;
    }

    public snackbarClose(objRef:any):void {
        objRef.hidden = true;
        try { this.conteiner.removeChild(objRef); } catch {}
    }

}

