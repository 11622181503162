import { BrowserModule }          from "@angular/platform-browser";
import { NgModule }               from "@angular/core";
import { AppRoutingModule }       from "./app-routing.module";
import { AppComponent }           from "./app.component";
import { NoopAnimationsModule }   from "@angular/platform-browser/animations";
import { LangService }            from "./services/lang.service";
import { UtilsService }           from "./services/utils.service"; 
import { WelcomeComponent }       from "./pages/welcome/welcome.component";
import { LoginComponent }         from "./pages/login/login.component";
import { AngularFirestoreModule } from "@angular/fire/firestore"; 
import { HttpService }            from "./services/http.service";
import { AdminComponent }         from "./pages/admin/admin.component";
import { AlertService }           from "./services/alert.service";
import { UserResumeComponent }    from "./pages/user-resume/user-resume.component";
import                                 "firebase/storage"
import { HttpClient, HttpClientModule } from "@angular/common/http";


@NgModule({
    declarations: [
        UserResumeComponent, 
        AppComponent,
        WelcomeComponent, 
        LoginComponent, 
        AdminComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule, 
        HttpClientModule, 
        NoopAnimationsModule, 
        AngularFirestoreModule
    ],
    providers: [
        HttpClient, 
        LangService, 
        UtilsService, 
        HttpService, 
        AlertService
    ],
    entryComponents: [ // necessário somente para componentes que serão usados no modal do material
    ], 
    bootstrap: [AppComponent]
})
export class AppModule { }
