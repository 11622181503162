import { Diploma, Experience, ItemOfResumeSection, Language, Resume, ResumeSection, Skill } 
                        from "src/app/zothers/models";
import { Component }    from "@angular/core";
import { Router }       from "@angular/router";
import { AlertService } from "src/app/services/alert.service";
import { HttpService }  from "src/app/services/http.service";
import { UtilsService } from "src/app/services/utils.service";
import { environment }  from "src/environments/environment";

/*
    BUGS
    o tamanho da tela da visualização do currículo, ainda tem aquele problema de aumenta e diminui de tamanho

    depois que iniciar o mvp
    botão duplicar resume, importante, para o usário criar um novo cv igual, e alterar alguns detalhes, eviatando ter que fazer um novo do zero

    se o mvp der certo
    inserir um componente 'fale conosco', colocar ele na landing page, no admin
    inserir um componente 'powerd by dizisme', colocar ele no rodapé dos currículos, ao clicar ele leva para uma página 'about us' que terá o componente 'fale conosco'
    inserir a página 'terms and conditions' e outra de privacy, terá um link para ela no componente 'fale conosco' e na página 'about us'
    na parte de configurations do usuário, colocar também os termos e o privacy
*/

@Component({ selector: "app-admin", templateUrl: "./admin.component.html", styleUrls: ["./admin.component.scss"] }) export class AdminComponent {

    public mode:string = "list";
    public navMenuVisible = false;
    public deleteAccountCheckInput:boolean = false;
    public resumeForm:Resume = null;
    public aboutTemp:string = null;
    public resumeJustAddedLink:string = null;
    public resumeJustAddedKey:string = null;
    private snack:any = null;
    private reservedKeys:string = "|app|demo|assets|";

    public ver(s):void {
        console.log(s);
    }

    constructor(public utils:UtilsService, private router:Router, private http:HttpService, private alertSev:AlertService) {
        if (!utils.userLogado || !utils.user) {
            var id:string = utils.getEncryptedUserFromSessionStorage();
            if (id) { // conferindo se o usuário já fez o login antes e já tem uma credencial em storage, se sim, permanece no admin, do contrário tem que logar novamente
                http.readUser(id).then(u => {
                    if (!u || (u.id != id)) router.navigate(["app/login"]); else {
                        this.utils.saveEncryptedUserOnSessionStorage(u);
                        this.utils.titleText("Diz Is Me");
                    }
                });
            }
            else {
                router.navigate(["app/login"]);
            }
        }
    }

    public textTitleResumes():string {
        if (!this.utils.user) return "";
        var ret:string = 
            this.utils.user.resumesKeys.length == 0 ? "You don't have any resumes yet, let's add one." :
            this.utils.user.resumesKeys.length == 1 ? "You have one resume" : 
            "You have " + this.utils.user.resumesKeys.length + " resumes"
        ;
        return ret;
    }

    public copyResumeLink(key:string):void {
        try {
            this.utils.copy((environment.production ? "www." : "http://") + environment.domain + key);
            this.alertSev.snackbar({ msg: "COPIED", closeBtn: true, type: "normal", timeOut: 600 });
        }
        catch {
            this.alertSev.snackbar({ msg: "ERROR, LINK NOT COPIED", closeBtn: true, type: "error", timeOut: 2000 });
        }
    }

    public openResume(key:string):void {
        this.utils.openLinkInNewTab((environment.production ? "https://" : "http://") + environment.domain + key);
    }

    public editResume(key:string, mode:string = "edit"):void {
        this.snack = this.alertSev.snackbar({ msg: "LOADING", type: "processing", closeBtn: false });
        this.mode = mode;
        fetch(environment.firebase.urlCdnPerfis + key + "-resume.json?alt=media").then(r => {
            r.text().then(d => {
                this.resumeForm = JSON.parse(d);
                this.alertSev.snackbarClose(this.snack);
                if (key == "demo" && this.mode == "add") this.resumeForm.resumeKey = null;
                this.aboutTemp = this.resumeForm.about.join("\n");
            });
        });
    }

    public resumeDelKey:string = "";
    public resumeDelInputBox:string = "";

    public deleteResume(key:string):void {
        this.mode = "delete";
        this.resumeDelKey = key;
        this.resumeDelInputBox = "";
        setTimeout(() => document.getElementById("delete-box").focus(), 400);
    }

    public deleteCommit():void {
        this.mode = "processing";
        this.http.deleteResume(this.resumeDelKey).then(() => {
            this.mode = "list";
            this.alertSev.snackbar({ msg: this.resumeDelKey + " DELETED!", type: "normal", timeOut: 1200 });
            this.resumeDelKey = ""
        })
        .catch(e => {
            this.mode = "delete";
            this.alertSev.snackbar({ msg: "ERRO, NOT DELETED!", type: "error", timeOut: 3000 });
        });
    }

    public addResume():void {
        this.resumeForm = new Resume();
        this.mode = "add";
    }

    public openNewAddedResume():void {
        this.utils.openLinkInNewTab("http" + (environment.production ? "s" : "") + "://" + environment.domain + this.resumeJustAddedKey);
        this.mode = "list";
    }

    public copyNewAddedResume():void {
        this.utils.copy((environment.production ? "www." : "http://") + environment.domain + this.resumeJustAddedKey);
        this.mode = "list";
        this.alertSev.snackbar({ msg: "COPIED", type: "normal", timeOut: 1000 });
    }

    public async saveResume():Promise<void> {
        var cleanKey:string = "";
        for (var i:number = 0; this.resumeForm.resumeKey && (i < this.resumeForm.resumeKey.length); i++) { // limpando a chave uma última vez
            if (this.keyFilterAccepted.indexOf(this.resumeForm.resumeKey[i]) >= 0) cleanKey += this.resumeForm.resumeKey[i];
        }
        this.resumeForm.resumeKey = cleanKey;
        if (!this.resumeForm.resumeKey) {
            this.resumeKeyValidationError("THIS FIELD IS REQUIRED");
            return;
        }
        else if (this.reservedKeys.indexOf("|" + this.resumeForm.resumeKey + "|") >= 0) {
            this.resumeKeyValidationError("THE KEY IS TAKEN, CHOOSE ANOTHER ONE");
            return;
        }
        else if (this.resumeForm.resumeKey.length < 4) {
            this.resumeKeyValidationError("THE KEY NEEDS A MINIMUM OF 4 CHARACTERES");
            return;
        }
        this.snack = this.alertSev.snackbar({ msg: "SAVING", type: "processing", closeBtn: false });
        var goAhead:Boolean = true;
        if (this.mode == "add") {
            await this.http.checkResumeKey(this.resumeForm.resumeKey).then(r => {
                if (r) {
                    this.alertSev.snackbarClose(this.snack);
                    this.resumeKeyValidationError("THE KEY IS TAKEN, CHOOSE ANOTHER ONE");
                    goAhead = false;
                }
            });
        }
        if (!goAhead) {
            this.alertSev.snackbarClose(this.snack);
            return;
        }
        this.resumeForm.about = this.aboutTemp ? this.aboutTemp.replace(/(\r\n|\n|\r)/gm, "#break#").split("#break#") : [];
        for (var i:number = 0; i < this.resumeForm.experiences.length; i++) { // retirando experiências vazias
            var exp:Experience = this.resumeForm.experiences[i];
            if (!exp.company && !exp.title && !exp.description) this.resumeForm.experiences.splice(i, 1);
        }
        for (var i:number = 0; i < this.resumeForm.skills.length; i++) { // retirando skills vazias
            var skill:Skill = this.resumeForm.skills[i];
            if (!skill.title && !skill.description) this.resumeForm.skills.splice(i, 1);
        }
        for (var i:number = 0; i < this.resumeForm.languages.length; i++) { // retirando languages vazias
            var lang:Language = this.resumeForm.languages[i];
            if (!lang.title) this.resumeForm.languages.splice(i, 1);
        }
        for (var i:number = 0; i < this.resumeForm.education.length; i++) { // retirando diplomas vazios
            var dip:Diploma = this.resumeForm.education[i];
            if (!dip.title && !dip.institution) this.resumeForm.education.splice(i, 1);
        }
        this.http.uploadResume(this.resumeForm, (this.mode == "add")).then(r => {
            this.alertSev.snackbarClose(this.snack);
            this.editClose(true);
            this.alertSev.snackbar({ msg: "SAVED", type: "normal", timeOut: 2000 });
            if (this.utils.user.cvCount == 1) {
                var btn = document.createElement("button");// primeiro cv criado, enviar evento google
                btn.setAttribute("onClick", "gtag_report_conversion()");
                btn.click();
                var btn2 = document.createElement("button");// primeiro cv criado, enviar evento microsoft
                btn2.setAttribute("onClick", "window.uetq = window.uetq || []; window.uetq.push('event', 'incluiu-cv', {});");
                btn2.click();
            }
        })
        .catch(e => {
            this.alertSev.snackbarClose(this.snack);
            this.alertSev.snackbar({ msg: "ERROR, RESUME NOT SAVED", type: "error", timeOut: 10000 });
        });
    }

    public resumeKeyValidationError(msg:string, focus:boolean = true):void {
        if (focus) document.getElementById("resume-key").focus();
        this.keyFilterWarning = msg;
        setTimeout(() => this.keyFilterWarning = null, 4000);
    }

    public editClose(showLinks:boolean = false):void {
        if (showLinks) {
            this.resumeJustAddedKey = this.resumeForm.resumeKey;
            this.resumeJustAddedLink = (environment.production ? "www." : "http://") + environment.domain + this.resumeForm.resumeKey;
            this.mode = "pos-add";
        }
        else {
            this.mode = "list";
        }
        this.resumeForm = null;
    }

    public showNavMenu(onOff:("on" | "off")):void {
        if (onOff == "on") {
            this.navMenuVisible = true;
            setTimeout(() => document.getElementById("focus-dummy").focus(), 200);
        }
        else {
            this.navMenuVisible = false;
        }
    }

    public itemAdd(collection:string):void {
        if      (collection == "experiences") this.resumeForm.experiences.push(new Experience());
        else if (collection == "skills"     ) this.resumeForm.skills.push(new Skill());
        else if (collection == "languages"  ) this.resumeForm.languages.push(new Language());
        else if (collection == "education"  ) this.resumeForm.education.push(new Diploma());
    }

    public itemRemove(collection:string, index:number) {
        (this.resumeForm[collection]).splice(index, 1);
    }

    public logOut():void {
        this.router.navigate([""]);
        window.sessionStorage.removeItem("id");
        this.utils.userLogado = false;
        this.utils.user = null;
    }

    public deleteAccountCheck():void {
        if (this.utils.user.resumesKeys && this.utils.user.resumesKeys.length > 0) this.alertSev.snackbar({ msg: "Please, delete all your resumes before deleting your account.", type:"warning" }); else this.mode = "delete-account";
    }

    public deleteAccount():void {
        this.mode = "processing";
        this.http.deleteUser(this.utils.user.id).then(() => {
            this.logOut();
            this.alertSev.snackbar({ msg:"YOUR ACCOUNT HAS BEEN DELETED", type:"normal", timeOut: 3000 });
        })
        .catch(e => {
            this.mode = "delete-account";
            this.alertSev.snackbar({ msg: "ERROR, ACCOUNT NOT DELETED", type: "error" });
        });
    }

    public addingNewSectionVisible:boolean = false;
    public newSectionName:string = "";

    public addingNewSection(onOff:("on" | "off")):void {
        this.addingNewSectionVisible = (onOff == "on");
        if (onOff == "on") {
            setTimeout(() => document.getElementById("new-section-name").focus(), 200);
        }
        else {
            this.newSectionName = "";
        }
    }

    public addNewSectionSave():void {
        this.resumeForm.otherSections.push(new ResumeSection(this.newSectionName));
        this.addingNewSection("off");
    }

    public otherSectionAddItem(section:ResumeSection):void {
        section.items.push(new ItemOfResumeSection());
    }

    public otherSectionRemoveItem(section:ResumeSection, index:number):void {
        section.items.splice(index, 1);
        if (section.items.length == 0) this.resumeForm.otherSections.splice(this.resumeForm.otherSections.indexOf(section), 1);
    }

    public async selectPhoto() {
        var arquivos:{ id:number, nome:string, tamanho:number, tipo:string }[] = [];
        var input = await document.createElement("input");
        input.type = "file";
        input.multiple = false;
        input.accept = "image/*";
        input.onchange = e => {
            arquivos = []; 
            var files:File[] = (e as any).target.files;
            for (var i:number = 0; i < files.length; i++) {
                var fileInfo = { id: arquivos.length, nome: files[i].name, tamanho: files[i].size, tipo: files[i].type};
                if (fileInfo.tamanho > 500 * 1000) { // 500KB de limite
                    this.alertSev.snackbar({ msg: "THE MAX SIZE IS 400KB", type: "warning", timeOut: 3000 })
                }
                else {
                    this.carregarBytesDoArquivo(files[i]);
                    arquivos.push(fileInfo);
                }
            }
        }
        input.click();
    }

    public removePhoto():void {
        this.resumeForm.photoBase64 = "../../../assets/person.png";
    }

    public carregarBytesDoArquivo(file:File):void { // recebendo o buffer usando by reference
        var reader = new FileReader();
        reader.onload = event => {
            this.resumeForm.photoBase64 = reader.result.toString();
        };
        reader.readAsDataURL(file);
    }

    public keyFilterWarning:string = null;
    public keyFilterAccepted:string = "abcdefghijklmnopqrstuvwxyz0123456789-_";
    public filterKey(e):void {
        if ((this.keyFilterAccepted.indexOf(e.key) < 0) && (["Tab", "Backspace", "Delete", "CapsLock"].indexOf(e.key) < 0)) {
            e.preventDefault();
            this.resumeKeyValidationError("only lowercase letters, numbers, dash(-) and underline(_) will be accepted", false);
        }
    }

    public showSaveButton():boolean {
        if (!this.resumeForm.name || !(this.resumeForm.email || this.resumeForm.cellphone)) return false;
        for (var i:number = 0; i < this.resumeForm.experiences.length; i++) {
            var exp:Experience = this.resumeForm.experiences[i];
            if (exp.title || exp.company || exp.description) return true;
        }
        for (var i:number = 0; i < this.resumeForm.skills.length; i++) {
            var skill:Skill = this.resumeForm.skills[i];
            if (skill.title || skill.description) return true;
        }
        for (var i:number = 0; i < this.resumeForm.education.length; i++) {
            var dip:Diploma = this.resumeForm.education[i];
            if (dip.title || dip.institution) return true;
        }
        return false;
    }
    
}
