import { Component, OnInit }     from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AlertService }          from "src/app/services/alert.service";
import { HttpService }           from "src/app/services/http.service";
import { UtilsService }          from "src/app/services/utils.service";
import { User }                  from "src/app/zothers/models";
import { environment }           from "src/environments/environment";

declare var FB:any; // ATENÇÃO, não pode mudar o nome desta variável, esse é o nome retornado pelo sdk do facebook, tem que ser assim para ocorrer a conexão

@Component({ selector: "app-login", templateUrl: "./login.component.html", styleUrls: ["./login.component.scss"] }) export class LoginComponent implements OnInit {

    public snack:any = null;

    constructor(private router:Router, private utils:UtilsService, private http:HttpService, private alertServ:AlertService) {
        var email:string = utils.getEncryptedUserFromSessionStorage();
        if (email) { // conferindo se o usuário já fez o login antes e já tem uma credencial em storage, se sim, loga direto sem passar pelo sso
            http.readUser(email).then(u => {
                if (u && u.email == email) {
                    utils.saveEncryptedUserOnSessionStorage(u);
                    router.navigate(["/app/admin"]);
                }
            });
        }
        else {
            router.events.subscribe(e => {
                var objRetornoAuth:any = null;
                if (e instanceof NavigationEnd && window.location.href.includes("googleapis.com") && window.location.href.includes("code=")) { // retorno google
                    this.snack = this.alertServ.snackbar({ msg: "LOGIN WITH GOOGLE", type: "processing", closeBtn: false });
                    var token = window.location.href.split("code=")[1].split("&")[0].replace("%2F", "/");
                    this.http.googleTrocarTokenPorDados(token).then((r:any) => {
                        objRetornoAuth = this.utils.jwtDecode(r.id_token);
                        this.logarOuIrParaTelaDeCadastro(objRetornoAuth.email, "google", objRetornoAuth.email, (objRetornoAuth.name || objRetornoAuth.given_name || null));
                    })
                    .catch(e => {
                        this.alertServ.snackbarClose(this.snack);
                        this.alertServ.snackbar({ msg: "ERROR ON YOUR GOOGLE LOGIN", type: "warning", timeOut: 2000 });
                        router.navigate(["app/login"]);
                    });
                }
                else if (e instanceof NavigationEnd && window.location.href.includes("fblogin=")) { // retorno facebook
                    this.snack = this.alertServ.snackbar({ msg: "LOGIN WITH FACEBOOK", type: "processing", closeBtn: false });
                    objRetornoAuth = JSON.parse(atob(decodeURIComponent(window.location.href.split("fblogin=")[1])));
                    http.facebookTrocarTokenPorDados(objRetornoAuth.userID, objRetornoAuth.accessToken).then(r => {
                        this.logarOuIrParaTelaDeCadastro("FB" + objRetornoAuth.userID, "facebook", (r.email || null), (r.name || null));
                    })
                    .catch(e => {
                        this.alertServ.snackbarClose(this.snack);
                        this.alertServ.snackbar({ msg: "ERROR ON YOUR FACEBOOK LOGIN", type: "warning", timeOut: 2000 });
                        router.navigate(["app/login"]);
                        console.log(JSON.stringify(e, null, 4));
                    });
                }
            })
        }
    }

    public ngOnInit():void {
        (window as any).fbAsyncInit = function() {
            FB.init(environment.facebook.credentials);
            FB.AppEvents.logPageView();      
        };
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    public close():void {
        this.router.navigate([""]);
    }

    public google():void {
        window.location.href = "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=" + environment.googleCloud.clientId + "&redirect_uri=" + encodeURIComponent(environment.loginRedirectUrl) + "&response_type=code&scope=email%20profile&prompt=login&flowName=GeneralOAuthFlow";
    }

    public facebook():void {
        FB.login((r) => {
            if (r.authResponse && r.authResponse.userID && r.status && r.status == "connected") {
                // esse js é externo, do facebook, se for usar código do angular dentro dele tem que usar o ngZone
                // 1 - injetar o ngZone no contrutor da classe: private ngZone:NgZone
                // 2 - embrulhar o código em uma ngZone: this.ngZone.run(() => { código angular });
                // exemplo: https://stackoverflow.com/questions/53645534/navigation-triggered-outside-angular-zone-did-you-forget-to-call-ngzone-run
                // por isso estou usando o href ao invés router.navigate, para evitar o ngZone
                window.location.href = "app/login?fblogin=" + encodeURIComponent(btoa(JSON.stringify(r.authResponse)));
            }
            else {
                this.alertServ.snackbar({ msg: "LOGIN FAILED", timeOut: 3000, type: "warning" });
            }
        });
    }

    public logarOuIrParaTelaDeCadastro(id:string, sso:string, email:string, name:string):void {
        this.router.navigate(["app/login"]);
        this.http.readUser(id).then((user:User) => {
            if (user) {
                this.alertServ.snackbarClose(this.snack);
                this.login(user);
            }
            else {
                var newUser:User = <User>{ id: id, email: email, name: name, sso: sso, resumesKeys: [], cvCount: 0, since: this.utils.yyyymmdd() };
                this.http.createUser(newUser).then(() => {
                    this.alertServ.snackbarClose(this.snack);
                    this.utils.primeiroAcesso = true;
                    this.login(newUser);
                }).
                catch(e => {
                    this.alertServ.snackbarClose(this.snack);
                    this.alertServ.snackbar({ msg: "LOGIN ERROR, PLEASE TRY AGAIN", type: "error", timeOut: 3000 });
                    console.log(e);
                });
            }
        })
        .catch(e => { // erro ao buscar o user na base, avisar o user e voltar para tela de login
            this.alertServ.snackbarClose(this.snack);
            this.alertServ.snackbar({ msg: "LOGIN ERROR, PLEASE TRY AGAIN", type: "error", timeOut: 3000 });
            console.log(e);
        });
    }

    private login(user:User):void {
        this.utils.saveEncryptedUserOnSessionStorage(user);
        this.router.navigate(["/app/admin"]); // ir para página tem que ser a última ação
    }

}
